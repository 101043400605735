import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "@emotion/styled";
import { graphql, Link } from "gatsby";
import { useQueryParam, StringParam } from "use-query-params";

import Section from "@components/Section";
import Seo from "@components/SEO";
import Layout from "@components/Layout";
import mediaqueries from "@styles/media";

const TaxonomyPage = (props) => {
  const {
    data: {
      articles: { edges },
      site: {
        siteMetadata: { category: categories },
      },
    },
  } = props;
  const articles = edges
    .map((edge) => edge.node)
    .filter((article) => !article.secret);
  let tagSet = new Set();
  articles.forEach((article) => {
    if (article.tags) {
      article.tags.forEach((tag) => tagSet.add(tag));
    }
  });
  const tagList = [...tagSet];

  // State
  var [categoryQuery, setCategoryQuery] = useQueryParam(
    "category",
    StringParam
  );
  var [tagQuery, setTagQuery] = useQueryParam("tag", StringParam);
  var [categorySelection, setCategorySelection] = useState(
    new Set(categoryQuery ? categoryQuery.split(",") : [])
  );
  var [tagSelection, setTagSelection] = useState(
    new Set(tagQuery ? tagQuery.split(",") : [])
  );
  var filterPost = () =>
    articles.filter((article) => {
      const { category, tags } = article;
      if (categorySelection.size !== 0 && !categorySelection.has(category)) {
        return false;
      } else if (tagSelection.size !== 0 && tags != null) {
        for (let posttag of tags) {
          if (tagSelection.has(posttag)) {
            return true;
          }
        }
        return false;
      }
      return true;
    });
  var [filteredPost, setFilteredPost] = useState(filterPost());

  // function
  var onTagClick = (tag) => {
    if (tagSelection.has(tag)) {
      tagSelection.delete(tag);
    } else {
      tagSelection.add(tag);
    }
    setTagSelection(tagSelection);
    setTagQuery([...tagSelection].join(","));
    setFilteredPost(filterPost());
  };

  var onCategoryClick = (category) => {
    if (categorySelection.has(category)) {
      categorySelection.delete(category);
    } else {
      categorySelection.add(category);
    }
    setCategorySelection(categorySelection);
    setCategoryQuery([...categorySelection].join(","));
    setFilteredPost(filterPost());
  };

  return (
    <Layout>
      <Seo />
      <Search>
        <Section>
          <h1>記事一覧</h1>
        </Section>
        <Section>
          <h2>CATEGORY</h2>
          {categories.map((category) => (
            <Toggle
              className={categorySelection.has(category.slug) ? "selected" : ""}
              key={category.slug}
              onClick={() => onCategoryClick(category.slug)}
            >
              {category.name}
            </Toggle>
          ))}
        </Section>
        <Section>
          <h2>TAG</h2>
          {tagList.map((tag) => (
            <Toggle
              className={tagSelection.has(tag) ? "selected" : ""}
              key={tag}
              onClick={() => onTagClick(tag)}
            >
              {tag}
            </Toggle>
          ))}
        </Section>
        <Section>
          <h2>記事一覧</h2>
          <ul>
            {filteredPost.length ? (
              filteredPost.map((post) => (
                <li key={post.slug}>
                  <Link to={post.slug}>{post.title}</Link>
                </li>
              ))
            ) : (
              <li>該当する記事がありません</li>
            )}
          </ul>
        </Section>
      </Search>
    </Layout>
  );
};

TaxonomyPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TaxonomyPage;

const Toggle = styled.button`
  outline: none;
  margin: 0 10px 10px 0;
  border-radius: 5px;
  padding: 5px;
  color: ${(p) => p.theme.colors.primary};
  border: 1px solid ${(p) => p.theme.colors.primary};
  cursor: pointer;
  &.selected {
    color: ${(p) => p.theme.colors.background};
    background: ${(p) => p.theme.colors.primary};
  }
`;

const Search = styled.div`
  margin: 100px auto 300px;
  padding-left: 68px;
  max-width: 749px;

  ${mediaqueries.desktop`
    padding-left: 53px;
    max-width: calc(507px + 53px);
    margin: 100px auto 200px;
  `}

  ${mediaqueries.tablet`
    padding-left: 0;
    margin: 100px auto 200px;
    max-width: 480px;
  `}

  ${(p) => mediaqueries.phablet`
    margin: 100px auto 180px;
    &::before {
      content: "";
      width: 100%;
      height: 19px;
      background: ${p.theme.colors.primary};
      position: absolute;
      left: 0;
      top: 0;
      transition: ${p.theme.colorModeTransition};
    }

    &::after {
      content: "";
      width: 100%;
      height: 10px;
      background: ${p.theme.colors.background};
      position: absolute;
      left: 0;
      top: 10px;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      transition: ${p.theme.colorModeTransition};
    }
  `}

  h1 {
    color: ${(p) => p.theme.colors.primary};
    font-size: 32px;
  }

  h2 {
    color: ${(p) => p.theme.colors.primary};
    font-size: 22px;
    margin: 20px 0 10px;
  }

  li {
    list-style: none;
    margin: 0 0 5px;
    a {
      color: ${(p) => p.theme.colors.primary};
      transition: all 0.2s;
      &:hover {
        color: ${(p) => p.theme.colors.accent};
      }
    }
  }
`;

export const query = graphql`
  query TaxonomyQuery {
    articles: allArticle(
      sort: { fields: [date, title], order: DESC }
      limit: 1000
    ) {
      edges {
        node {
          id
          slug
          secret
          title
          author
          date(formatString: "YYYY/MM/DD")
          update(formatString: "YYYY/MM/DD")
          dateForSEO: date
          updateForSEO: update
          timeToRead
          canonical_url
          subscription
          category
          tags
        }
      }
    }
    site {
      siteMetadata {
        category {
          slug
          name
        }
      }
    }
  }
`;
